<template>
  <v-toolbar-items class="w-full">
    <v-text-field
      ref="search"
      :class="searchFocused ? 'focused-search' : 'search-field'"
      v-model="search"
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify"
      single-line
      @focus="handleSearchFocus"
      @blur="handleSearchBlur"
      @change="handleSearchEnter"
      @click:clear="handleSearchClear"
    ></v-text-field>
    <sub-menu name="Filters" :menu-items="filterMenuItems" @sub-menu-click="onMenuItemClick" />
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text class="text-capitalize" v-on="on"> Sort <v-icon>mdi-menu-down</v-icon> </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(s, i) in sortOptions" :key="i" @click="sortAssignments(i)">
          <v-list-item-title>
            {{ s.label }}
          </v-list-item-title>
          <v-list-item-icon v-if="assignmentListSort.index == i">
            <v-icon>
              {{ assignmentListSort.order == 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="operations.length" color="#000" text v-bind="attrs" v-on="on" class="text-capitalize">
          Operations <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <template v-for="(operation, index) in operations">
          <v-list-item v-if="operation.show" :key="index" @click="operation.onClick">
            <v-list-item-title>{{ operation.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-menu offset-y v-if="me.yellowfinId">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#000" text v-bind="attrs" v-on="on" class="text-capitalize">
          Reports <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="me.is.superAdmin || me.is.transportationAdmin" @click="openReportDashboard">
          <v-list-item-title>Open Report Builder</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <template v-for="(report, index) in reports">
          <v-list-item
            v-if="report.show"
            :key="index"
            @click="$router.push(`/assignments/report/${report.reportUUID}`)"
          >
            <v-list-item-title>{{ report.name }}</v-list-item-title>
            <v-list-item-icon v-if="report.icon">
              <v-icon>{{ report.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
    <v-menu
      v-if="activeFilters.length"
      v-model="activeFiltersMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      min-width="350px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="text-capitalize" v-bind="attrs" v-on="on" :class="{ flash: filtersChanged }">
          <v-icon left>mdi-filter</v-icon>
          Active Filters ({{ activeFilters.length }})
        </v-btn>
      </template>

      <v-card class="card-content">
        <v-list class="active-filter-field">
          <Chip
            v-for="(filter, index) in activeFilters"
            :key="index"
            close
            @click:close="removeFilter(filter.value)"
            class="chips"
            wrap
          >
            {{ filter.text }}
          </Chip>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions class="card-actions">
          <v-btn color="#ff4242" text @click="removeActiveFilters"> Clear All </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-btn text color="#000" @click="refresh" class="text-capitalize">
      <v-icon left>mdi-refresh</v-icon>
      Refresh
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import { uniqBy, uniq, groupBy } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import SubMenu from '@/components/SubMenu.vue';
import { Chip } from '@/components/shared';
import { EXPORT_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import { GET_VEHICLE_OWNERS } from '@/store/modules/User/actions';
import { GET_YELLOWFIN_TOKEN } from '@/store/modules/User/actions';
import assignmentApi from '@/apis/assignment';
import { assignmentReports } from '@/reports';
import { ASSIGNMENT_STATUS } from '@/shared/common';
import { randomString, getAssignmentVehicleType } from '@/util';

export default {
  name: 'AssignmentListMenu',
  inject: ['eventHub'],
  components: { SubMenu, Chip },
  data() {
    return {
      search: '',
      actives: [],
      manualInput: [],
      combinedSearch: [],
      activeFiltersMenu: false,
      filtersChanged: false,
      vehicleOwners: [],
      searchFocused: false,
      sortOptions: [
        { label: 'Depart Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'tripRequestId' },
        { label: 'Assignment Date', sortBy: 'assignedAt' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Vehicle Owner', sortBy: 'vehicleOwner' },
        { label: 'Vehicle', sortBy: 'vehicleNumber' },
        { label: 'Vehicle Type', sortBy: 'vehicleType' },
      ],
      currentSort: 0,
      reports: assignmentReports,
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('location', ['locations', 'locationsById', 'zones']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('fundingSource', ['fundingSourcesById', 'fundingSources']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('driver', ['drivers']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('user', ['users', 'usersById', 'me', 'usersByEmail', 'yellowfinToken']),
    ...mapGetters('user', { theVehicleOwners: 'vehicleOwners' }),
    ...mapGetters('config', ['driverConfig']),
    ...mapGetters('app', ['assignmentListFilters', 'assignmentListSort', 'isMobile']),
    requesters() {
      const requesterIds = uniq(this.assignments.filter((e) => e.submittedUser).map((e) => e.submittedUser));
      const list = requesterIds
        .map((e) => this.usersById[e])
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
      return list.filter((user) => user !== undefined);
    },
    tripTypeFilters() {
      const ttIds = uniq(this.assignments.filter((e) => e.tripTypeId).map((e) => e.tripTypeId));
      return ttIds
        .map((e) => ({
          name: this.tripTypesById[e].name,
          search: { field: 'tripTypeId', value: e, text: 'Trip Type ' + this.tripTypesById[e].name },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    tripEventFilters() {
      const teIds = uniq(
        this.assignments
          .filter((e) => e.tripEventIds.length)
          .map((e) => e.tripEventIds)
          .flat()
      );
      return teIds
        .map((e) => {
          const tripEvent = this.tripEventsById[e];
          const tripType = tripEvent?.tripTypeId ? this.tripTypesById[tripEvent.tripTypeId]?.name : null;
          if (!tripEvent?.name || !tripType) {
            return null; // skip if trip event or its name is null or undefined
          }
          return {
            name: `${tripEvent.name} (${tripType})`,
            search: { field: 'tripEventId', value: e, text: 'Trip Event ' + tripEvent.name },
          };
        })
        .filter(Boolean) // remove null values in the list
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    locationFilters() {
      const dIds = uniq(this.assignments.filter((e) => e.locationId).map((e) => e.locationId));
      return dIds
        .map((e) => ({
          name: `${this.locationsById[e]?.name} (${this.locationsById[e]?.code})`,
          search: { field: 'locationId', value: e, text: 'Location ' + this.locationsById[e]?.name },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    destinationFilters() {
      const dIds = uniq(this.assignments.filter((e) => e.destinationId).map((e) => e.destinationId));
      return dIds
        .map((e) => {
          const destination = this.destinationsById[e];
          if (destination) {
            return {
              name: destination.name,
              search: { field: 'destinationId', value: e, text: 'Destination ' + destination.name },
            };
          } else {
            return null; // Return null for destination objects that are undefined
          }
        })
        .filter((item) => item !== null)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    fundingSourceFilters() {
      const fsIds = uniq(
        this.tripRequests
          .filter((e) => e.fundingSources.length)
          .map((e) => e.fundingSources.map((f) => f.fundingSourceId))
          .flat()
      );
      const filters = fsIds
        .map((e) => ({
          name: this.fundingSourcesById[e]?.name || 'None',
          search: {
            field: 'fundingSourceId',
            value: e,
            text: 'Funding Source ' + (this.fundingSourcesById[e]?.name || 'None'),
          },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return [{ name: 'Any', search: { field: 'fundingSource', value: 'any', text: 'Any Funding Source' } }].concat(
        filters
      );
    },
    vehicleTypeFilters() {
      const vehicleTypes = uniq(this.assignments.map((e) => getAssignmentVehicleType(e)).filter((e) => e));
      return vehicleTypes
        .map((e) => ({
          name: e.name,
          search: { field: 'vehicleTypeId', value: e.id, text: 'Vehicle Type ' + e.name },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    vehicleOwnerFilters() {
      const byUser = groupBy(this.vehicleOwners, 'userEmail');
      return Object.keys(byUser)
        .map((e) => {
          const name = this.usersByEmail[e] ? this.usersByEmail[e].displayName || e : e;
          return {
            name,
            search: {
              field: 'vehicleOwner',
              value: e,
              text: 'Vehicle Owner ' + name,
            },
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    operations() {
      if (this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin || this.me.is.vehicleOwner)
        return [
          { name: 'Find Available Vehicles', onClick: this.findVehicles, show: true },
          { name: 'Mass Assign To Recurring Trips', onClick: this.batchAssign, show: true },
          { name: 'Download Trip Tickets', onClick: this.download, show: true },
          {
            name: 'Send Weekly Notifications',
            onClick: this.sendWeeklyNotifications,
            show: this.me.is.superAdmin,
          },
          {
            name: 'Export Assignments',
            onClick: this.export,
            show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
          },
          { name: 'Driver Logs', onClick: this.openDriverLogs, show: true },
        ];
      else return [];
    },
    filterMenuItems() {
      return [
        {
          name: 'Date',
          menu: [
            {
              name: 'Trips From This Day Forward',
              search: { field: 'date', value: 'upcoming', text: 'Upcoming' },
            },
            {
              name: 'Today',
              search: { field: 'date', value: 'today', text: 'Today' },
            },
            {
              name: 'Date Range',
              onClick: () => this.eventHub.$emit('openDateRangeFilterForAssignments'),
            },
          ],
        },
        {
          name: 'Driver Status',
          hide: !this.driverConfig.driverAccept && !this.driverConfig.driverDecline,
          menu: [
            {
              name: 'No Driver Assigned',
              search: { field: 'status', value: ASSIGNMENT_STATUS.NONE, text: 'No Driver Assigned' },
            },
            {
              name: 'Pending',
              search: { field: 'status', value: ASSIGNMENT_STATUS.PENDING, text: 'Pending' },
            },
            {
              name: 'Self Accepted',
              search: { field: 'status', value: ASSIGNMENT_STATUS.SELF_ACCEPTED, text: 'Self Accepted' },
            },
            {
              name: 'Admin Accepted',
              search: { field: 'status', value: ASSIGNMENT_STATUS.ADMIN_ACCEPTED, text: 'Admin Accepted' },
            },
            {
              name: 'Self Declined',
              search: { field: 'status', value: ASSIGNMENT_STATUS.SELF_DECLINED, text: 'Self Declined' },
            },
            {
              name: 'Admin Declined',
              search: { field: 'status', value: ASSIGNMENT_STATUS.ADMIN_DECLINED, text: 'Admin Declined' },
            },
          ],
        },
        {
          name: 'Declined & Pending Assignments',
          hide: !this.driverConfig.driverAccept && !this.driverConfig.driverDecline,
          menu: [
            {
              name: '< 1 Day Pending Assignment',
              search: { field: 'pending', value: 1, text: '< 1 Day Pending Assignment' },
            },
            {
              name: '< 2 Days Pending Assignment',
              search: { field: 'pending', value: 2, text: '< 2 Days Pending Assignment' },
            },
            {
              name: '3+ Days Pending Assignment',
              search: { field: 'pending', value: 3, text: '3+ Days Pending Assignment' },
            },
            {
              name: 'Declined Assignments',
              search: { field: 'declined', value: true, text: 'Declined Assignments' },
            },
          ],
        },
        {
          name: 'General',
          menu: [
            {
              name: 'Trip #',
              onClick: () => this.eventHub.$emit('openIdFilterForAssignments'),
            },
            {
              name: 'Needs Driver',
              search: { field: 'pendingDriver', value: 1, text: 'Needs Driver' },
            },
            {
              name: 'Needs Vehicle',
              search: { field: 'pendingVehicle', value: 1, text: 'Needs Vehicle' },
            },
            {
              name: 'Mileage Entered',
              search: { field: 'endMileage', value: true, text: 'Mileage Entered' },
            },

            {
              name: 'Mileage Not Entered',
              search: { field: 'startMileage', value: true, text: 'Mileage Not Entered' },
            },
            { name: 'Hours Entered', search: { field: 'endTime', value: true, text: 'Hours Entered' } },
            { name: 'Hours Not Entered', search: { field: 'startTime', value: true, text: 'Hours Not Entered' } },
            {
              name: 'With Vehicle Comments',
              search: { field: 'vehicleNotes', value: true, text: 'With Vehicle Comments' },
            },
            // {
            //   name: 'Pickup Only',
            //   search: { field: 'pickupOnly', value: 1, text: 'Pickup Only' },
            // },
            // {
            //   name: 'Dropoff Only',
            //   search: { field: 'dropoffOnly', value: 1, text: 'Dropoff Only' },
            // },
            // {
            //   name: 'Recurring',
            //   search: { field: 'recurring', value: 1, text: 'Recurring' },
            // },
          ],
        },
        {
          name: 'Vehicle Owner',
          menu: this.vehicleOwnerFilters,
        },
        {
          name: 'Driver',
          menu: this.drivers.map((e) => ({
            name: `${e.firstName} ${e.lastName}`,
            search: {
              field: 'driver',
              value: e.id || `${e.firstName} ${e.lastName}`,
              text: 'Driver ' + `${e.firstName} ${e.lastName}`,
            },
          })),
        },
        {
          name: 'Vehicle',
          menu: this.vehicles.map((e) => ({
            name: e.name,
            search: { field: 'vehicleId', value: e.id, text: 'Vehicle ' + e.name },
          })),
        },
        {
          name: 'Vehicle Location',
          menu: this.locations
            .filter((e) => e.isVehicleLocation)
            .map((e) => ({
              name: `${e.name} (${e.code})`,
              search: { field: 'vehicleLocation', value: e.id, text: 'Vehicle Location ' + e.name },
            })),
        },
        {
          name: 'Trip Location',
          menu: this.locationFilters,
        },
        {
          name: 'Destination',
          menu: this.destinationFilters,
        },
        {
          name: 'Trip Type',
          menu: this.tripTypeFilters,
        },
        {
          name: 'Trip Event',
          menu: this.tripEventFilters,
        },
        {
          name: 'Funding Source',
          menu: this.fundingSourceFilters,
        },
        {
          name: 'Trip Batch #',
          menu: uniqBy(
            this.assignments
              .filter((e) => e.batchId)
              .sort((a, b) => b.batchId - a.batchId)
              .map((e) => ({
                name: `#${e.batchId}`,
                search: { field: 'batchId', value: e.batchId, text: 'Batch #' + e.batchId },
              })),
            'name'
          ),
        },
        {
          name: 'Vehicle Type',
          menu: this.vehicleTypeFilters,
        },
        {
          name: 'Zone',
          menu: this.zones.map((e) => ({
            name: e.text || e,
            search: { field: 'zone', value: e, text: 'Zone ' + e },
            disabled: e.disabled,
          })),
        },
        {
          name: 'Requester',
          menu: this.requesters.map((e) => ({
            name: e.displayName,
            search: { field: 'submittedUser', value: e.id, text: 'Requester ' + e.displayName },
          })),
        },
        {
          name: 'Funding Approver',
          menu: uniqBy(
            this.fundingSources.filter((e) => e.approverId),
            'approverId'
          )
            .map((e) => ({
              name: `${e.approverUsername} (${e.approverEmail})`,
              search: {
                field: 'fundingApprover',
                value: e.approverId,
                text: `Funding Approver ${e.approverUsername} (${e.approverEmail})`,
              },
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ].filter((item) => item.menu && item.menu.length > 0 && !item.hide);
    },
    activeFilters() {
      return this.actives;
    },
  },
  created() {
    this.eventHub.$on('addFilterAssignments', (item) => this.onMenuItemClick(item));
    this.eventHub.$on('defaultFilterAssignments', () => this.defaultFilter());
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('addFilterAssignments');
    this.eventHub.$off('defaultFilterAssignments');
  },
  methods: {
    ...mapActions('assignment', [EXPORT_ASSIGNMENTS]),
    ...mapActions('user', [GET_VEHICLE_OWNERS, GET_YELLOWFIN_TOKEN]),
    sortAssignments(index) {
      this.currentSort = index;
      this.eventHub.$emit('setAssignmentSort', index);
    },
    refresh() {
      this.eventHub.$emit('refreshAssignmentList');
    },
    async fetchItems() {
      this.vehicleOwners = !this.theVehicleOwners.length ? await this.getVehicleOwners() : this.theVehicleOwners;
    },
    removeActiveFilters() {
      this.actives = [];
      if (this.search) this.eventHub.$emit('filterAssignments', this.manualInput);
      this.activeFiltersMenu = false;
    },
    removeFilter(valueToRemove) {
      this.actives = this.actives.filter((filter) => filter.value !== valueToRemove);
      if (!this.actives.length) this.activeFiltersMenu = false;
    },
    findVehicles() {
      this.eventHub.$emit('findVehiclesRequestedForAssignments');
    },
    batchAssign() {
      this.eventHub.$emit('batchAssignRequestedForAssignments');
    },
    download() {
      this.eventHub.$emit('downloadRequestedForAssignments');
    },
    async sendWeeklyNotifications() {
      const ok = await this.$myconfirm('Are you sure you want to send weekly notifications?');
      if (ok) await assignmentApi.sendWeeklyNotifications();
    },
    export() {
      this.exportAssignments({ uuid: randomString(16) });
    },
    async openReportDashboard() {
      const { token } = await this.getYellowfinToken();
      window
        .open(
          `${process.env.VUE_APP_YELLOWFIN_SERVER}/logon.i4?LoginWebserviceId=${token}&disablelogoff=true&entry=VIEWDASHBOARD&dashboardUUID=a1f25fa4-c6e1-497e-bab5-0f77c732dbbe&yftoolbar=TRUE`,
          '_blank'
        )
        .focus();
    },
    onMenuItemClick(item) {
      if (item.onClick) item.onClick();
      else {
        const activeIndex = this.actives.findIndex((e) => e == item.search);
        //no duplicate date field
        const isDateField = (field) => ['date', 'dateRange'].includes(field);
        const sameDateFieldIndex = this.actives.findIndex(
          (e) => isDateField(item.search.field) && isDateField(e.field)
        );
        if (activeIndex >= 0) this.actives.splice(activeIndex, 1);
        else if (sameDateFieldIndex >= 0) {
          this.actives.splice(sameDateFieldIndex, 1);
          this.actives.push(item.search);
        } else this.actives.push(item.search);
      }
    },
    defaultFilter() {
      if (this.assignmentListFilters.length)
        for (let filter of this.assignmentListFilters) this.onMenuItemClick({ search: filter });
    },
    handleSearchFocus() {
      this.searchFocused = true;
    },
    handleSearchBlur() {
      this.searchFocused = false;
    },
    handleSearchEnter() {
      if (!this.search) return;

      this.manualInput = [{ field: 'text', value: this.search, text: this.search }];

      this.combinedSearch = this.actives.length ? this.manualInput.concat(this.actives) : this.manualInput;

      this.eventHub.$emit('filterAssignments', this.combinedSearch);
    },
    handleSearchChange(e) {
      if (this.search) this.search = this.search.trim();
      else this.search = '';
      if (this.search.length && this.search[this.search.length - 1] != ';') this.search += ';';
      const values = this.search
        .split(';')
        .map((e) => e.trim())
        .filter((e) => e);
      const activeTexts = this.actives.map((e) => e.text);
      for (let v of values)
        if (!activeTexts.includes(v))
          this.onMenuItemClick({
            name: e,
            search: { field: 'text', value: v, text: v },
          });
      for (let i = 0; i < this.actives.length; i++)
        if (!values.includes(this.actives[i].text)) this.actives[i].remove = true;
      this.actives = this.actives.filter((e) => !e.remove);
    },
    handleSearchClear() {
      setTimeout(() => {
        this.search = '';
        this.manualInput = [];
        this.searchFocused = false;
        this.$refs.search.blur();
      }, 50);
    },
    openDriverLogs() {
      this.eventHub.$emit('openDriverLogs');
    },
  },
  watch: {
    actives(value) {
      if (value.length) {
        if (this.manualInput.length) {
          this.combinedSearch = this.actives.concat(this.manualInput);
        } else {
          this.combinedSearch = this.actives;
        }
        this.eventHub.$emit('filterAssignments', this.combinedSearch);
      } else if (this.manualInput.length) {
        this.eventHub.$emit('filterAssignments', this.manualInput);
      } else {
        this.eventHub.$emit('filterAssignments', []);
      }
      //add flashing effect to active filters
      this.filtersChanged = true;
      setTimeout(() => {
        this.filtersChanged = false;
      }, 500);
    },
    search(value) {
      if (!value && this.actives.length) {
        this.eventHub.$emit('filterAssignments', this.actives);
      } else if (!value) {
        this.eventHub.$emit('filterAssignments', []);
      }
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
  align-items: center;
  padding-left: 16px;
}
.focused-search {
  width: calc(100vw - 351px);
  position: absolute;
  z-index: 200;
  background-color: #f5f5f5;
  height: 100%;
  align-items: center;
}
.w-full {
  width: 100%;
}
.card-content {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.active-filter-field {
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-actions {
  display: flex;
  justify-content: center;
}
::v-deep .chips {
  margin: 5px;
  border: 2px solid #59c129;
}
::v-deep .chips .v-chip:not(.v-chip--active) {
  background: #f5f5f5;
}
::v-deep .chips .v-chip__close {
  color: #ff4242;
}
.flash {
  animation: flashAnimation 0.5s ease-in-out;
}
@keyframes flashAnimation {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
